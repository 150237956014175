<template>
  <v-row class=" mx-0">
    <v-col cols="12" sm="2" md="2" lg="2">
    </v-col>
    <v-col cols="12" sm="8" md="8" lg="8">
      <v-card class="py-10">
        <div v-if="order_master_id == null">
        </div>
        <div v-else>
            <v-row justify="center" class="mb-12 mx-0">
                <v-col cols="12" sm="10" md="8" class="pa-0">
                    <h2 class="ml-2 mb-4" align="center"><v-icon color="success">fa-check-circle</v-icon> เพิ่มออเดอร์สำเร็จ</h2>
                    <div class="separator"></div>
                </v-col>
            </v-row>
          <v-card-text>
            <v-row justify="center" class="mb-12 mx-0">
                <v-col cols="12" sm="10" md="8" class="pa-0">
                    <h2 class="ml-2 mb-4">Order เลขที่: {{ order_master_id }}</h2>
                </v-col>
            </v-row>

            <v-row justify="center" class="mb-12 mx-0">
                <v-col cols="12" sm="10" md="8" class="pa-0">
                    <h2 class="ml-2 mb-2">
                        URL สำหรับลูกค้า
                        <v-btn text color="info" @click="copy_text()">
                            คัดลอกลิงก์
                        </v-btn>
                    </h2>
                    <div class="px-2 py-4">
                        <a :href="customer_url" target="_blank">{{ customer_url }}</a>
                        <input id="customer_url" type="hidden" :value="customer_url">
                    </div>
                </v-col>
            </v-row>
          </v-card-text>
            <v-row justify="center" class="mb-12 mx-0">
                <v-col cols="12" sm="10" md="8">
                    <!-- <div class="separator"></div> -->
                    <v-row class=" mx-0">
                        <v-col cols="12" sm="12" lg="4"    align="center">
                            <v-btn color="success" :to="url_ref" style="width:100%;"   >
                                <div><v-icon>mdi-plus</v-icon> เพิ่มออเดอร์จากข้อมูลเดิม</div>
                            </v-btn>
                        </v-col>
                        <v-col cols="12" sm="12" lg="4" align="center">
                            <v-btn color="primary" style="width:100%;"  to="/add-order" @click="localStorage.removeItem('productlist_new_order_ref')">
                                <div><v-icon>mdi-plus</v-icon> เพิ่มออเดอร์ใหม่</div>
                            </v-btn>
                        </v-col>
                        <v-col cols="12" sm="12" lg="4" align="center">
                            <v-btn color="info"  style="width:100%;"  to="/order">
                                <div><v-icon>mdi-home</v-icon> กลับหน้าหลัก</div>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>
      </v-card>
    </v-col>
  </v-row>


</template>

<script>
    import { format_order_id } from '@/website/global_function'
    import { customerWebsite } from '@/website/global'

    export default {
        data: () => ({
            order_master_id: null,
            customer_url: null
        }),

        created () {
            this.order_master_id = localStorage.getItem("OrderMasterID")
            var order_master_id_tmp = localStorage.getItem("OrderMasterID")
            var order_token = localStorage.getItem("Token")
            localStorage.removeItem("OrderMasterID")
            localStorage.removeItem("Token")
            if (this.order_master_id == null) {
                this.$router.push('/add-order')
            } else {
                this.order_master_id = format_order_id(this.order_master_id)
                this.customer_url = customerWebsite + 'purchase-order=' + order_token
            }

            this.url_ref = "/add-order?ref="+order_master_id_tmp
        },

        methods: {
            format_order_id,

            copy_text() {
                let copy_text = document.getElementById("customer_url")
                if (copy_text != null) {
                    copy_text.setAttribute('type', 'text')
                    copy_text.select()
                    var is_copy = document.execCommand('copy')
                    copy_text.setAttribute('type', 'hidden')
                    var msg = is_copy ? 'สำเร็จ' : 'ไม่สำเร็จ'
                    this.$swal({
                        position: 'top',
                        type: 'success',
                        title: 'Copy ' + msg,
                        timer: 1000,
                        showConfirmButton: false,
                        toast: true
                    })
                } else {
                    this.$swal({
                        position: 'top',
                        type: 'error',
                        text: 'เกิดข้อผิดพลาด กรุณาติดต่อ Admin'
                    })
                }
            },
        }
    }
</script>
